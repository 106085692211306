<template>
  <div class="bg-black FoundersGrotesk" id="footer">
    <b-row>
      <b-col cols="7" md="6" class="first-col">
        <div v-for="(link, index) in footerColumn1" :key="index">
          <a :href="link.url" target="_blank" class="lg-link">{{
            link.text
          }}</a>
        </div>
      </b-col>
      <b-col cols="12" md="4" class="text-right">
        <div v-for="(link, index) in footerColumn2" :key="index">
          <a :href="link.url" target="_blank" class="md-link">{{
            link.text
          }}</a>
        </div>
      </b-col>
      <b-col cols="12" md="1" class="text-right p-md-0">
        <div v-for="(link, index) in footerColumn3" :key="index">
          <a :href="link.url" target="_blank" class="md-link">{{
            link.text
          }}</a>
        </div>
      </b-col>
      <b-col cols="12" md="1" class="text-right p-md-0">
        <div v-for="(link, index) in footerColumn4" :key="index">
          <a :href="link.url" target="_blank" class="md-link">{{
            link.text
          }}</a>
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footerColumn1: [{ text: "Ode Dundas", url: "#" }],
      footerColumn2: [
        {
          text: "1417 DUNDAS STREET W.",
          url: "https://goo.gl/maps/qU6sGrNxGRtWjyf19",
        },
        { text: "tiffany@odetoronto.ca", url: "mailto:tiffany@odetoronto.ca" },
      ],
      footerColumn3: [
        {
          text: "INSTAGRAM",
          url: "https://www.instagram.com/ode_toronto/?utm_medium=copy_link",
        },
        { text: "FACEBOOK", url: "https://www.facebook.com/OdeToronto" },
      ],
      footerColumn4: [
        {
          text: "SPOTIFY",
          url: "https://www.instagram.com/ode_toronto/?utm_medium=copy_link",
        },
        { text: "TIKTOK", url: "https://www.tiktok.com/@ode_toronto" },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
#footer {
  padding: 30px;
  a:link,
  a:visited,
  a:hover,
  a:active {
    color: $m-white;
    text-decoration: none;
  }
  .first-col {
    @media screen and (max-width: 768px) {
      padding-bottom: 25px;
    }
  }
  .lg-link {
    font-size: 25px;
  }
  .md-link {
    font-size: 15px;
    @media screen and (max-width: 900px) {
      font-size: 12px;
      line-height: 5px;
    }
    @media screen and (max-width: 768px) {
      font-size: 15px;
    }
  }
}
</style>
